import Masonry from 'react-responsive-masonry';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';

import { CategoryTreeItem } from 'epromo-types/Inventory';
import { addLeadingSlash, UrlSlugProp } from 'epromo-lib/utils/slug';
import { useAppStateTrackedStore } from 'epromo-lib/store/app';

import { SubCategoryColumn } from '@components/organisms/SubCategoryColumn';

type SubCategoryColumnsLayoutProps = {
  category?: CategoryTreeItem;
  localeProp: UrlSlugProp;
};

export function SubCategoryColumnsLayout({
  category,
  localeProp,
}: SubCategoryColumnsLayoutProps) {
  const router = useRouter();

  const { setCategoriesDropMenuOpen } = useAppStateTrackedStore();
  if (!category) {
    return null;
  }
  return (
    <nav
      className="category-nav bg-neutral-100"
      aria-labelledby="subcategories-heading"
    >
      <Masonry columnsCount={3}>
        {category?.childCategories?.map((childCategory) => (
          <div key={`${category.id}-${childCategory.id}`} className="z-10 ">
            <button
              className={clsx(
                'flex flex-col justify-start',
                'w-full p-2 text-sm',
                'hover:text-skin-text-hover text-secondary-500'
              )}
              onClick={() => {
                setCategoriesDropMenuOpen(false);
                const catUrl = `${addLeadingSlash(childCategory[localeProp])}`;
                router.replace(catUrl);
              }}
            >
              <span className="ml-4 text-left text-sm font-bold">
                {childCategory.name}
              </span>
            </button>
            {childCategory.childCategories && (
              <SubCategoryColumn {...childCategory} localeProp={localeProp} />
            )}
          </div>
        ))}
      </Masonry>
    </nav>
  );
}

import { HTMLAttributes, useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { debounce } from 'throttle-debounce';
import { Transition } from '@headlessui/react';
import SimpleBarCore from 'simplebar-core';

import ScrollDownChevron from '../assets/icons/scrollDownChevron.svg';

export type ScrollDownAnimatedGradient = {
  onButtonClick?: () => void;
  isVisible?: boolean;
};

export function ScrollDownAnimated({
  className,
  onClick,
}: HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type="button"
      onClick={onClick}
      id="scroll-down-animation-container"
      className={clsx(
        'w-9 h-[52px] bg-neutral-100 rounded-[80px]',
        'flex flex-col items-center',
        className
      )}
    >
      <ScrollDownChevron
        id="scroll-down-icon-top"
        className="scroll-down-icon"
      />
      <ScrollDownChevron
        id="scroll-down-icon-bottom"
        className="scroll-down-icon stroke-business-800"
      />
    </button>
  );
}
export function ScrollDownAnimatedGradient({
  isVisible = true,
  onButtonClick,
}: ScrollDownAnimatedGradient) {
  return (
    <Transition
      show={isVisible}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <>
        <div
          className={clsx(
            'absolute bottom-0 right-0 h-16 w-full',
            'bg-gradient-to-b from-transparent to-[#F2F3F7]',
            'pointer-events-none'
          )}
        />
        <ScrollDownAnimated
          className="absolute bottom-3 right-3"
          onClick={onButtonClick}
        />
      </>
    </Transition>
  );
}

export function useSimpleBarScrollDownAnimation() {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const [simpleBarStateRef, setSimpleBarStateRef] =
    useState<SimpleBarCore | null>(null);

  const checkScrollBottom = debounce(300, () => {
    const scrollEl = simpleBarStateRef?.getScrollElement();
    if (scrollEl) {
      setIsBottom(
        scrollEl.scrollTop + scrollEl.clientHeight === scrollEl.scrollHeight
      );
    }
  });

  useEffect(() => {
    setIsOverflowing(!!simpleBarStateRef?.axis.y.isOverflowing);
    const scrollEl = simpleBarStateRef?.getScrollElement();

    if (scrollEl) {
      scrollEl.addEventListener('scroll', checkScrollBottom);

      return () => {
        scrollEl.removeEventListener('scroll', checkScrollBottom);
      };
    }
  }, [simpleBarStateRef]);

  const scrollToBottom = () => {
    simpleBarStateRef?.getScrollElement()?.scrollTo({
      top: simpleBarStateRef?.getContentElement()?.offsetHeight,
      behavior: 'smooth',
    });
  };

  return {
    simpleBarRef: setSimpleBarStateRef,
    showScrollDownSign: isOverflowing && !isBottom,
    isOverflowing,
    scrollToBottom,
  };
}

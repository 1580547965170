import { useRouter } from 'next/router';
import { clsx } from 'clsx';

import { CategoryTreeItem } from 'epromo-types/Inventory';
import { addLeadingSlash, UrlSlugProp } from 'epromo-lib/utils/slug';
import { useAppState } from 'epromo-lib/store/app';

interface CategoryColumnProps extends CategoryTreeItem {
  localeProp: UrlSlugProp;
  currentCategory?: CategoryTreeItem;
}

export function SubCategoryColumn({
  localeProp,
  childCategories,
}: CategoryColumnProps) {
  const router = useRouter();
  const { setCategoriesDropMenuOpen } = useAppState();

  return (
    <>
      {childCategories.map((category) => (
        <div key={category.id} className="z-10 break-inside-avoid">
          <button
            className={clsx(
              'flex w-full items-center px-2 py-1 text-neutral-600',
              'hover:text-skin-text-hover text-left text-[13px] leading-5'
            )}
            onClick={() => {
              setCategoriesDropMenuOpen(false);
              const url = `${addLeadingSlash(category[localeProp])}`;
              router.replace(url);
            }}
          >
            <span className="ml-4">{category.name}</span>
          </button>
        </div>
      ))}
    </>
  );
}

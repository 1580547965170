import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import SimpleBar from 'simplebar-react';
import { clsx } from 'clsx';
import 'simplebar-react/dist/simplebar.min.css';
import Link from 'next/link';

import { addLeadingSlash, resolveLocaleProp } from 'epromo-lib/utils/slug';
import { CategoryTreeItem } from 'epromo-types/Inventory';
import { useDetectMobileUserAgent } from 'epromo-lib/hooks/useDetectMobile';
import { useAppStateTrackedStore } from 'epromo-lib/store/app';

import { SubCategoryColumnsLayout } from '@components/molecules/SubCategoryColumnsLayout';

import {
  ScrollDownAnimatedGradient,
  useSimpleBarScrollDownAnimation,
} from '@components/atoms/ScrollDownAnimated';
import { Icon } from '@components/atoms/Icon/Icon';

function hoveredCategoryClasses(
  currentCategory: CategoryTreeItem,
  category?: CategoryTreeItem
) {
  const hovered = category && currentCategory.id === category.id;
  return clsx(
    hovered && 'bg-neutral-100 rounded-tl-md rounded-bl-md prod-category'
  );
}

type SlideDownMenuProps = {
  flatInventoryList?: CategoryTreeItem[] | undefined;
  isGlobalNotificationVisible?: boolean;
  nativeAppNotificationIsClosed: boolean;
};

const bottomMargin = 20;
const maxHeight = 720;

export function SlideDownMenu({
  flatInventoryList,
  isGlobalNotificationVisible,
  nativeAppNotificationIsClosed,
}: SlideDownMenuProps) {
  const { t } = useTranslation('common');
  const router = useRouter();
  const localeProp = resolveLocaleProp(router.locale);

  const { setCategoriesDropMenuOpen, categoriesDropMenuOpen } =
    useAppStateTrackedStore();
  const { isMobileUserAgent } = useDetectMobileUserAgent();
  const defaultCategory =
    flatInventoryList && flatInventoryList[0]
      ? flatInventoryList[0]
      : undefined;

  const [category, setCategory] = useState<CategoryTreeItem | undefined>(
    defaultCategory
  );
  const expandedMenu = useRef<HTMLDivElement>(null);
  const mouseIsInsideExpandedMenu = useRef<boolean>(false);
  const { simpleBarRef, isOverflowing, scrollToBottom, showScrollDownSign } =
    useSimpleBarScrollDownAnimation();

  const debouncedSetCategory = useCallback((cat: CategoryTreeItem) => {
    setCategory(cat);
  }, []);

  const globalNotificationOffset = isGlobalNotificationVisible ? 48 : 0;

  const moreMarginFromTop = isMobileUserAgent && !nativeAppNotificationIsClosed;
  const marginTop = moreMarginFromTop ? 196 : 112 + globalNotificationOffset;
  const height = `calc(100vh - ${marginTop + bottomMargin}px)`;
  const heightStyles = {
    height,
    maxHeight,
  };

  useEffect(() => {
    if (!categoriesDropMenuOpen) {
      setCategory(undefined);
    } else {
      window.setTimeout(() => {
        setCategory(defaultCategory);
      }, 50);
    }
  }, [categoriesDropMenuOpen]);

  return (
    <>
      <button
        id="btn-categories-burger"
        type="button"
        onClick={() => setCategoriesDropMenuOpen(!categoriesDropMenuOpen)}
        className={clsx(
          'text-secondary-500',
          'hidden lg:flex',
          'relative items-center justify-center space-x-2',
          'text-sm font-medium transition-colors duration-200 ease-out'
        )}
      >
        <Icon
          name={categoriesDropMenuOpen ? 'close' : 'categories'}
          className="h-6 w-6"
        />
        <span>{t('allProducts')}</span>
      </button>
      <Transition.Root show={categoriesDropMenuOpen} as={Fragment}>
        <Dialog unmount as="div" onClose={setCategoriesDropMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={clsx(
                'fixed inset-0 bg-neutral-500',
                'bg-opacity-75 transition-opacity z-20'
              )}
            />
          </Transition.Child>
          <div className="fixed inset-0 z-20 overflow-y-hidden">
            <div
              className={clsx('flex min-h-full items-start', 'ml-8')}
              style={{
                marginTop,
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={clsx(
                    'relative w-screen',
                    'max-w-4xl xl:max-w-7xl',
                    'transform overflow-hidden rounded-lg',
                    'flex bg-neutral-100',
                    'h-min text-left shadow-xl transition-all'
                  )}
                  style={heightStyles}
                >
                  <div
                    className={clsx(
                      'flex h-full w-80 flex-col',
                      'flex-shrink-0 rounded-lg bg-white'
                    )}
                    ref={expandedMenu}
                  >
                    <SimpleBar
                      className="py-8 pl-2"
                      style={{
                        overflowX: 'hidden',
                        ...heightStyles,
                      }}
                    >
                      <ul>
                        {flatInventoryList &&
                          flatInventoryList.map((cat) => (
                            <li
                              key={`root-over-${cat.id}`}
                              className={clsx(
                                'w-full py-2.5 pl-6 text-left',
                                'relative text-sm font-medium text-black',
                                hoveredCategoryClasses(cat, category)
                              )}
                            >
                              <Link
                                href={`${addLeadingSlash(cat[localeProp])}`}
                                className={clsx(
                                  'flex w-full gap-x-2 text-left',
                                  'relative text-sm font-medium text-black',
                                  'flex items-center overflow-hidden pr-4'
                                )}
                                onClick={() => {
                                  setCategoriesDropMenuOpen(false);
                                  setCategory(undefined);
                                }}
                                onMouseEnter={() => debouncedSetCategory(cat)}
                              >
                                <img
                                  src={cat.webIcon}
                                  alt={cat.name}
                                  className="h-6 w-6 flex-shrink-0"
                                />
                                <span>{cat.name}</span>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </SimpleBar>
                  </div>
                  <div
                    className="h-full"
                    onMouseEnter={() => {
                      mouseIsInsideExpandedMenu.current = true;
                    }}
                    onMouseLeave={() => {
                      mouseIsInsideExpandedMenu.current = false;
                    }}
                  >
                    {category && (
                      <div
                        className={clsx(
                          'w-[575px] xl:w-[960px]',
                          'h-full overflow-hidden relative'
                        )}
                      >
                        <SimpleBar
                          key={category?.id}
                          ref={simpleBarRef}
                          className={clsx(
                            'px-2 py-6',
                            isOverflowing && 'pb-14'
                          )}
                          style={heightStyles}
                        >
                          <SubCategoryColumnsLayout
                            localeProp={localeProp}
                            category={category}
                          />
                        </SimpleBar>
                        <ScrollDownAnimatedGradient
                          isVisible={showScrollDownSign}
                          onButtonClick={scrollToBottom}
                        />
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
